.layout {
  position: relative;
  display: grid;
  grid-template-columns: 65.76% 31.29%;
  gap: 39px;
  width: 91.666667vw;
  margin: 0 auto;

  & .sidebar {
    position: relative;
  }

  @media screen and (max-width: 1240px) {
    grid-template-columns: 60% 37%;
    gap: 30px;
  }

  @media screen and (max-width: 820px) {
    width: auto;
    margin-right: 32px;
    margin-left: 32px;
    grid-template-columns: 100%;

    & .sidebar {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      z-index: 100;
    }
  }

  @media screen and (max-width: 650px) {
    margin-right: 16px;
    margin-left: 16px;
  }
}
