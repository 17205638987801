.personal-estimate {
  margin-top: 62px;
  padding-left: 70px;
  padding-right: 70px;

  @media screen and (max-width: 769px) {
    padding-left: 19px;
    padding-right: 19px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  & .personal-estimate-container {
    height: auto;
    border-radius: 40px;
    background-color: #f3f3ff;

    & .estimate-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 46px 0;
      padding-right: 131px;

      @media screen and (max-width: 1500px) {
        padding-right: 60px;
      }

      @media screen and (max-width: 825px) {
        padding-right: 10px;
        padding-left: 10px;
      }

      @media screen and (max-width: 769px) {
        flex-direction: column;
        align-items: center;
        padding-left: 19px;
        padding-right: 19px;
      }

      .estimate-container-second {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        flex-grow: 2;
        max-width: 470px;

        .text {
          margin-bottom: auto;
          margin-right: 10px;
        }

        @media screen and (max-width: 769px) {
          margin: 10px 0;
        }
      }

      & .desc {
        font-family: Lato;
        font-size: 14px;
        font-weight: 300;
        line-height: 21px;
        letter-spacing: 0.2800000011920929px;
        text-align: left;
      }

      & .block-title {
        margin-top: 0px;
        margin-right: 0px;
        padding-right: 0px;
      }

      .icon {
        width: 32px;
        height: 19.81px;

        @media screen and (max-width: 769px) {
          margin-right: 16px;
        }
      }

      & .estimate-button {
        width: 223px;
        height: 48px;
        padding: 12px 38px 12px 38px;
        border-radius: 40px;
        gap: 10px;
        background: #8585ff;
        cursor: pointer;
        box-shadow: 0px 15px 30px 0px #8585ff33;
        display: flex;
        justify-content: center;
        align-items: center;

        .loader-estimate {
          width: 24px;
          height: 24px;
          border: 5px solid #fff;
          border-bottom-color: transparent;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        .estimate-button-text {
          color: #ffffff;
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
        }
      }

      .disable {
        background: #a1a1ab;
      }
    }

    .purpulRow {
      background-color: #fbfbff;
    }

    .whiteRow {
      background-color: #ffffff;
    }

    .separate {
      margin-top: 32px;
      border-top: 2px dotted #7e7e9b;
      width: calc(100% - 131px - 131px);
      margin-left: 131px;

      @media screen and (max-width: 1500px) {
        width: calc(100% - 60px - 60px);
        margin-left: 60px;
      }

      @media screen and (max-width: 500px) {
        width: calc(100% - 32px);
        margin-left: 16px;
      }
    }

    .containerTable {
      padding-left: 131px;
      padding-right: 131px;

      @media screen and (max-width: 1500px) {
        padding-left: 60px;
        padding-right: 60px;
      }

      @media screen and (max-width: 500px) {
        padding-left: 16px;
        padding-right: 16px;
        overflow: hidden;
      }

      .tableHeader {
        position: sticky;
        z-index: 10;
        top: 0;
        left: 70px;
        right: 70px;
        display: grid;
        grid-template-columns: 3fr 4fr repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding: 32px 16px;
        background: #f9f9ff;
        border-radius: 10px;
        margin-bottom: 10px;

        div:nth-child(1) {
          width: 200px;
          max-width: 189px;
        }

        div {
          width: 92px;
          max-width: 89px;
        }

        .disable {
          display: none;
        }

        @media screen and (max-width: 500px) {
          display: none;
          flex-direction: row;
          flex-wrap: wrap;
          justify-content: space-between;

          div {
            display: flex;
            width: calc((100% - 32px) / 3);
            margin-top: 8px;
          }

          div:nth-child(1) {
            width: 100%;
          }

          div:nth-child(2) {
            width: 100%;
          }

          .disable {
            display: flex;
            width: 100%;
          }
        }

        .tableHeaderText {
          font-family: Lato;
          font-size: 14px;
          font-weight: 700;
          line-height: 17px;
          letter-spacing: 0.5px;
          text-align: left;
          color: #3f3f64;
        }

        .center {
          text-align: center;
          max-width: 89px;

          @media screen and (max-width: 500px) {
            text-align: left;
          }
        }
      }

      .table {
        display: grid;
        grid-template-columns: 3fr 4fr repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        padding: 16px;
        border-radius: 10px;
        margin-bottom: 8px;
        position: relative;

        @media screen and (max-width: 500px) {
          display: flex;
          margin-top: 32px;
          flex-direction: row;
          flex-wrap: wrap;
        }

        .tableRowNumber {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-grow: 1;
          width: 92px;
          column-gap: 5px;
          border-left: #f3f3ff 1px solid;
          max-width: 89px;

          .mobileNumber {
            display: none;
            font-family: Lato;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #7e7e9b;

            @media screen and (max-width: 500px) {
              display: inline;
            }
          }

          .number {
            font-family: Lato;
            font-size: 20px;
            font-weight: 900;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #3f3f64;

            @media screen and (max-width: 500px) {
              padding-top: 16px;
              padding-bottom: 16px;
            }
          }

          @media screen and (max-width: 500px) {
            width: calc((100% / 3) - 16px);
            max-width: calc((100% / 3) - 16px);
            border-left: 0px;
            border-top: #f3f3ff 1px solid;
            display: flex;
            flex-direction: column;
            padding-top: 16px;
            align-items: flex-start;
            justify-content: space-between;

            div:nth-child(1) {
              width: 100%;
            }

            div:nth-child(2) {
              width: 100%;
            }

            .disable {
              display: flex;
              width: 100%;
            }

            .number {
              text-align: center;
            }
          }
        }

        .distance {
          margin-left: 0px;

          @media screen and (max-width: 500px) {
            margin-left: 20px;
          }
        }

        .disable {
          display: none;

          @media screen and (max-width: 500px) {
            display: flex;
            width: 100%;
          }
        }

        .tableRow:nth-child(2) {
          padding-left: 16px;

          @media screen and (max-width: 500px) {
            margin-top: 16px;
            padding-left: 0px;
            margin-bottom: 16px;
          }
        }

        .tableRow {
          @media screen and (max-width: 500px) {
            width: 100%;
          }

          .title {
            font-family: Lato;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #7e7e9b;
          }

          .text {
            font-family: Lato;
            font-size: 14px;
            font-weight: 900;
            line-height: 17px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #27273d;
            margin-top: 16px;
            margin-bottom: 16px;

            @media screen and (max-width: 500px) {
              font-family: Lato;
              font-size: 16px;
              font-weight: 900;
              line-height: 19px;
              letter-spacing: 0.5px;
              text-align: left;
            }
          }

          .title2 {
            font-family: Lato;
            font-size: 12px;
            font-weight: 500;
            line-height: 14px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #7e7e9b;
          }

          .text2 {
            font-family: Lato;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0.5px;
            text-align: left;
            color: #27273d;
            margin-top: 16px;
          }
        }

        .deleteContainer {
          cursor: pointer;
          position: absolute;
          right: -8px;
          top: -8px;

          .deleteIcon {
            width: 29px;
            height: 29px;
          }
        }
      }

      .plusFeature {
        cursor: pointer;
        box-shadow: 0px 15px 30px 0px #8585ff33;
        background: #8585ff;
        height: 60px;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 32px;

        .loader-estimate {
          width: 24px;
          height: 24px;
          border: 5px solid #fff;
          border-bottom-color: transparent;
          border-radius: 50%;
          display: inline-block;
          box-sizing: border-box;
          animation: rotation 1s linear infinite;
        }

        @keyframes rotation {
          0% {
            transform: rotate(0deg);
          }

          100% {
            transform: rotate(360deg);
          }
        }

        .plusIcon {
          width: 20px;
          height: 20px;
        }

        .plusText {
          font-family: Lato;
          font-size: 16px;
          font-weight: 900;
          line-height: 19px;
          letter-spacing: 0.5px;
          text-align: left;
          color: #f8f8ff;
          padding-left: 12px;
        }
      }

      .budget-container {
        display: grid;
        grid-template-columns: 3fr 4fr repeat(8, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        margin-top: 32px;
        margin-bottom: 32px;

        @media screen and (max-width: 500px) {
          display: flex;
          flex-wrap: wrap;
          background-color: #27273d;
          border-radius: 10px;
        }

        .budget-first-section {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
          background-color: #27273d;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 22px;
          padding-bottom: 22px;

          @media screen and (max-width: 500px) {
            border-bottom-left-radius: 0px;
            border-top-right-radius: 10px;
            width: 100%;
            border-bottom: 1px solid #dfdef41a;
            margin-left: 16px;
            margin-right: 16px;
          }

          .budget-title {
            font-family: Lato;
            font-size: 16px;
            font-weight: 800;
            line-height: 19px;
            letter-spacing: 0.5px;
            color: #ffffff;
            padding-left: 32px;

            @media screen and (max-width: 500px) {
              padding-left: 0px;
            }
          }

          .budget-text {
            padding-left: 12px;
          }

          .budget-text-bluer {
            padding-left: 12px;
          }
        }

        .budget-second-section {
          background-color: #27273d;
          display: flex;
          flex-direction: row;
          align-items: center;
          padding-top: 22px;
          padding-bottom: 22px;

          @media screen and (max-width: 500px) {
            width: 100%;
            border-bottom: 1px solid #dfdef41a;
            margin-left: 16px;
            margin-right: 16px;
          }

          .budget-title {
            font-family: Lato;
            font-size: 16px;
            font-weight: 800;
            line-height: 19px;
            letter-spacing: 0.5px;
            color: #ffffff;
            padding-left: 32px;

            @media screen and (max-width: 500px) {
              padding-left: 0px;
            }
          }

          .budget-text {
            padding-left: 12px;
          }

          .budget-text-bluer {
            padding-left: 12px;
          }
        }

        .budget-first-little-section {
          background-color: #27273d;
          align-items: center;
          padding-top: 22px;
          padding-bottom: 22px;

          .budget-text-mobile {
            display: none;

            @media screen and (max-width: 500px) {
              display: inline;
              font-family: Lato;
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: 0.5px;
              text-align: left;
              color: #dfdef4;
              margin-bottom: 10px;
            }
          }

          @media screen and (max-width: 500px) {
            width: calc((100% / 3) - 20px);
            max-width: calc((100% / 3) - 20px);
            border-bottom-left-radius: 0px;
            border-bottom: 1px solid #dfdef41a;
            margin-left: 16px;
          }

          p {
            @media screen and (max-width: 500px) {
              text-align: left;
            }
          }
        }

        .budget-center-little-section {
          background-color: #27273d;
          align-items: center;
          padding-top: 22px;
          padding-bottom: 22px;

          .budget-text-mobile {
            display: none;

            @media screen and (max-width: 500px) {
              display: inline;
              font-family: Lato;
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: 0.5px;
              text-align: left;
              color: #dfdef4;
              margin-bottom: 10px;
            }
          }

          @media screen and (max-width: 500px) {
            width: calc((100% / 3) - 20px);
            max-width: calc((100% / 3) - 20px);
            border-bottom: 1px solid #dfdef41a;
            margin-left: 16px;
          }

          p {
            @media screen and (max-width: 500px) {
              text-align: left;
            }
          }
        }

        .budget-last-little-section {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          background-color: #27273d;
          align-items: center;
          padding-top: 22px;
          padding-bottom: 22px;

          .budget-text-mobile {
            display: none;

            @media screen and (max-width: 500px) {
              display: inline;
              font-family: Lato;
              font-size: 12px;
              font-weight: 500;
              line-height: 14px;
              letter-spacing: 0.5px;
              text-align: left;
              color: #dfdef4;
              margin-bottom: 10px;
            }
          }

          @media screen and (max-width: 500px) {
            width: calc((100% / 3) - 32px);
            max-width: calc((100% / 3) - 32px);
            border-top-right-radius: 0px;
            border-bottom-right-radius: 10px;
          }

          p {
            @media screen and (max-width: 500px) {
              text-align: left;
            }
          }
        }

        .distance {
          margin-left: 0px;

          @media screen and (max-width: 500px) {
            margin-left: 16px;
          }
        }

        .mobile-bottom-left-radius {
          @media screen and (max-width: 500px) {
            border-bottom-left-radius: 10px;
          }
        }
      }

      .get-estimate {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        .get-estimate-button {
          cursor: pointer;
          width: 225px;
          height: 60px;
          padding: 12px 48px 12px 48px;
          border-radius: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #8585ff;
          margin-bottom: 32px;

          .get-estimate-button-text {
            font-family: Lato;
            font-size: 16px;
            font-weight: 700;
            line-height: 24px;
            letter-spacing: 0px;
            text-align: center;
            color: #ffffff;
          }
        }
      }
    }
  }
}

.modal-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .modal-estimate-container {
    height: 642px;
    position: relative;

    .modal-estimate-header {
      padding: 20px;
      position: relative;
      background: #f8f8ff;
      height: 306px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;

      @media screen and (max-width: 374px) {
        padding: 10px;
        width: 300px;
        margin: 0 auto;
      }

      .modal-estimate-header-title {
        font-family: Lato;
        font-size: 36px;
        font-weight: 900;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;
        color: #27273d;
        margin-top: 36px;

        &.width {
          margin-top: 56px;
          max-width: 470px;
        }
      }

      .modal-estimate-header-desc {
        font-family: Lato;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0.20000000298023224px;
        text-align: center;
        color: #7e7e9b;
        margin-top: 24px;
      }

      .modal-estimate-header-image {
        width: 206.16px;
        height: 179.92px;
        position: absolute;
        top: 170px; // 200px;
      }
    }

    .modal-estimate-body {
      padding: 20px;
      background: #ffffff;
      height: 336px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;

      &.bg {
        background: #f8f8ff;
      }

      .title-label {
        font-family: Lato;
        font-size: 11px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.27px;
        text-align: left;
        color: #7e7e9b;
      }

      @media screen and (max-width: 374px) {
        padding: 10px;
        width: 300px;
        margin: 0 auto;
      }

      .modal-estimate-body-input {
        width: calc(100% - 50px);

        &:first-child {
          margin-bottom: 30px;
        }
      }

      .modal-estimate-body-button {
        border: none;
        width: 300px;
        height: 48px;
        padding: 12px 30px 12px 30px;
        border-radius: 40px;
        margin-top: 72px;
        background: #8585ff;

        &.confirm {
          margin-top: 178px;
          box-shadow: 0px 15px 30px 0px #8585ff33;
        }

        &.mt-0 {
          margin-top: 0;
        }

        &:disabled {
          background: grey;
        }

        @media screen and (max-width: 374px) {
          width: 260px;
        }

        @media screen and (min-width: 769px) {
          width: 470px;
        }

        .modal-estimate-body-button-text {
          cursor: pointer;
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .modal-close-button {
      position: absolute;
      right: -10px;
      top: -10px;

      .modal-close-button-image {
        cursor: pointer;
        width: 42px;
        height: 42px;
      }
    }
  }

  .modal-feature-container {
    height: 642px;
    position: relative;

    .modal-feature-header {
      padding: 20px;
      background: #ffffff;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-top-right-radius: 40px;
      border-top-left-radius: 40px;
      height: 306px;

      @media screen and (max-width: 374px) {
        padding: 10px;
        width: 300px;
        margin: 0 auto;
      }

      .modal-feature-header-title {
        font-family: Lato;
        font-size: 36px;
        font-weight: 900;
        line-height: 43px;
        letter-spacing: 0px;
        text-align: center;
        color: #27273d;
        margin-top: 42px;
      }

      .modal-feature-header-input {
        margin-top: 42px;
        width: calc(100% - 64px);
      }
    }

    .modal-feature-body {
      padding: 20px;
      background: #f8f8ff;
      height: 336px;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom-left-radius: 40px;
      border-bottom-right-radius: 40px;

      @media screen and (max-width: 374px) {
        padding: 10px;
      }

      .modal-feature-body-text {
        font-family: Lato;
        font-size: 28px;
        font-weight: 900;
        line-height: 34px;
        letter-spacing: 0.5px;
        text-align: center;
        color: #3f3f64;
        margin-top: 42px;
      }

      .modal-feature-body-input {
        margin-top: 42px;
        width: calc(100% - 64px);
      }

      .modal-feature-body-add-button {
        cursor: pointer;
        border: none;
        width: 300px;
        height: 48px;
        padding: 12px 30px 12px 30px;
        border-radius: 40px;
        background: #8585ff;
        margin-top: 60px;

        @media screen and (max-width: 374px) {
          width: 260px;
        }

        @media screen and (min-width: 769px) {
          width: 470px;
        }

        .modal-feature-body-add-text {
          cursor: pointer;
          font-family: Lato;
          font-size: 16px;
          font-weight: 700;
          line-height: 24px;
          letter-spacing: 0px;
          text-align: center;
          color: #ffffff;
        }
      }
    }

    .modal-close-button {
      position: absolute;
      right: -10px;
      top: -10px;

      .modal-close-button-image {
        cursor: pointer;
        width: 42px;
        height: 42px;
      }
    }
  }
}

.budget-text {
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffffff;
}

.budget-text-bluer {
  font-family: Lato;
  font-size: 24px;
  font-weight: 900;
  line-height: 29px;
  letter-spacing: 0.5px;
  text-align: left;
  color: #ffffff;
  color: transparent;
  text-shadow: 0 0 8px #ffffff;
}
