.rate-container {
  position: relative;
  width: 91.666667vw;
  margin: -8vw auto 0;
  padding: 3.5vw 7.8vw 5.5vw;
  border-radius: 40px;
  background: #f3f3ff;
  /* url('/images/rate-bg.svg') no-repeat right center /
    contain;*/

  .block-title {
    margin-top: 0px;
    margin-bottom: 16px;
    padding: 0;
  }

  .AI_questions {
    margin-top: 100px;
  }

  .img {
    display: block;
    position: absolute;
    top: 15%;
    height: auto;
    background: transparent;
    border-radius: 100px;
    width: 240px;
    transform: translateY(-46%) rotate(-358deg);
    right: 33px;
  }

  .AIQuestionsCard-card {
    height: auto;

    .img {
      margin-bottom: 16px;
      position: relative;
      right: 0px;
      top: 0px;
      transform: translateY(0%) rotate(0deg);
    }

    & .title {
      margin: auto 0 8px;
      font-size: 18px;
      font-weight: 900;
      line-height: 29px;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
    }

    & .icon {
      height: 59px;
      width: auto;
    }

    & .note {
      font-weight: 300;
      font-size: 14px;
      color: #27273d;
      margin-bottom: 32px;
    }
  }

  @media screen and (max-width: 1240px) {
    & .block-title h6 {
      max-width: 70%;
    }
  }

  @media screen and (max-width: 820px) {
    width: auto;
    margin-top: -11vw;
    margin-left: 32px;
    margin-right: 32px;
    padding-left: 32px;
    padding-right: 32px;
    background-image: unset;

    & .block-title h6 {
      max-width: 100%;
    }

    & .img {
      display: none;
    }
    .AI_questions {
      margin-top: 60px;
    }
  }

  @media screen and (max-width: 650px) {
    margin-top: -35vw;
    margin-left: 16px;
    margin-right: 16px;
    padding: 32px;
  }
}

.rate {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 22px;
  padding: 0;

  label {
    flex: 1 1 auto;
    padding-right: 200px;
    line-height: 1.5em;
    font-weight: 300;
  }

  .input-container {
    flex: 0 0 283px;
    display: flex;
    align-items: stretch;
    height: 56px;
    border: 1px solid #dfdef4;
    border-radius: 1px;
    background: #fff;
    box-sizing: border-box;

    .input-change {
      flex: 0 0 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 28px;
      font-weight: 400;
      line-height: 1.5em;
      cursor: pointer;
      border: none;
      background: white;

      &.disabled {
        pointer-events: none;
      }
    }

    .input-wrapper {
      flex: 0 0 149px;
      display: flex;
      align-items: flex-end;
      gap: 4px;
      border-left: 1px solid #dfdef4;
      border-right: 1px solid #dfdef4;
      padding: 9px 16px;
      line-height: 1.5em;
    }

    input {
      padding: 0;
      border: none;
      width: 85px;
      height: 100%;
      outline: none;
      font-style: normal;
      font-stretch: normal;
      border-radius: 0;
      color: #27273d;
      background: transparent;
      text-align: right;
      font-family: Lato;
      font-size: 36px;
      font-weight: 700;
      line-height: 1em;
      letter-spacing: 0px;
    }

    span {
      font-weight: 400;
      color: #7e7e9b;
    }
  }

  @media screen and (min-width: 1900px) {
    .block-title h6 {
      max-width: 1200px;
    }
  }

  @media screen and (min-width: 821px) and (max-width: 1240px) {
    flex-direction: column-reverse;
    align-items: flex-start;

    & .input-container {
      flex: 0 0 auto;
      width: 285px;
    }
  }

  @media screen and (max-width: 820px) {
    align-items: flex-start;

    & label {
      padding: 0;
    }
  }

  @media screen and (max-width: 650px) {
    flex-direction: column-reverse;

    & .input-container {
      flex: 0 0 auto;
      width: 100%;

      & .input-wrapper {
        flex: 1 1 100%;
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 400px) {
    & .input-container {
      & .input-change {
        flex: 0 1 56px;
      }
    }
  }
}

.tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  z-index: 10;
  white-space: nowrap;
  margin-top: 101px;
  padding: 0 5px;
}
