footer {
  background-color: #27273d;
  padding-top: 65px;
  padding-bottom: 45px;
}

footer ul {
  padding: 0;
  margin: 0;
}
footer .font--h4,
footer .font--14 {
  color: #f8f8ff;
  transition: color 0.3s ease-in;
}
footer h4.font--h4 {
  margin-bottom: 0.5rem;
}
footer p.font--14.pale.no-hover {
  margin-bottom: 1rem;
}
.no-hover {
  cursor: default;
  pointer-events: none;
}
footer .font--h4:not(.no-hover):hover,
footer .font--14:not(.no-hover):hover {
  color: #b4b4ff;
}
footer .font--14 {
  opacity: 0.6;
}
.footer.col-9 {
  display: grid;
  grid-template-columns: 1.5fr 3fr;
  margin: 0 auto;
  column-gap: 105px;
  padding: 0;
  max-width: unset;
}
body:not(.main-page) .footer,
body:not(.main-page) .footer-copyright-upd {
  width: 75%;
  padding: 0;
}
.links-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 0.5fr;
}
.footer .footer-links {
  width: unset;
}
.footer .social-wrap-upd {
  display: flex;
  gap: 24px;
}
.footer .social-icon-wrap {
  width: 40px;
  height: 40px;
  background: #7e7e9b;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: background 0.3s ease-in;
  margin: 0;
}
.footer .social-icon-wrap:hover {
  background: #8181cd;
}

footer .pale {
  color: #dfdef4;
}
.footer-logo-wrap-upd .pale {
  margin: 16px 0;
}
.white-cards-wrapper {
  display: flex;
  gap: 16px;
  margin-top: 65px;
}

.white-cards-wrapper-bottom {
  margin-top: 16px !important;
  margin-bottom: 5px;
}

.footer-white-card {
  width: 140px;
  height: 48px;
  background: #ffffff;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-item {
  margin-bottom: 10px;
}
.footer-copyright-upd {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
}
.h4-mt {
  margin-top: 32px;
}
@media (max-width: 1300px) {
  .footer.col-9 {
    grid-template-columns: 1fr 3fr;
    column-gap: 90px;
  }
  .footer .social-wrap-upd {
    gap: 16px;
  }
  .footer-white-card {
    width: 122px;
    height: 42px;
  }
  .footer .social-wrap-upd {
    margin: 40px 0;
  }
  .white-cards-wrapper {
    margin: 0;
  }
}
@media (max-width: 1024px) {
  .links-wrapper-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .footer-links:nth-child(2) {
    width: 35%;
  }
}
@media (max-width: 820px) {
  .footer-copyright-upd,
  .footer,
  body:not(.main-page) .footer,
  body:not(.main-page) .footer-copyright-upd {
    width: 90%;
    max-width: 90%;
    padding: 0;
  }
  .footer.col-9 {
    column-gap: 40px;
  }
  .links-wrapper-grid .footer-links:not(:last-child) {
    width: 47%;
  }
  .links-wrapper-grid .footer-links:last-child {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .links-wrapper-grid .footer-links:last-child div {
    width: 47%;
  }
  .footer-copyright-upd {
    margin-top: 32px;
  }
  .h4-mt {
    margin-top: 0;
  }
  .footer-white-card {
    width: 140px;
    height: 48px;
  }
  .white-cards-wrapper {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  footer {
    padding-top: 40px;
  }
  .footer-logo {
    height: 40px;
  }
}

@media (max-width: 650px) {
  .white-cards-wrapper {
    flex-direction: row;
  }
  .footer.col-9 {
    grid-template-columns: 1fr;
    gap: 32px;
  }
  .white-cards-wrapper .footer-white-card {
    width: 100%;
    height: 54px;
  }
  .footer-copyright-upd {
    flex-direction: column-reverse;
    display: flex;
    align-items: center;
  }
  .social-wrap-upd {
    justify-content: space-between;
    width: 100%;
  }
  .footer-links a.font--h4 {
    font-size: 14px;
    line-height: 120%;
  }
  .footer-copyright-upd .font--14 {
    font-size: 11px;
    line-height: 18px;
  }
  .footer-copyright-upd div:last-child {
    margin-bottom: 8px;
  }
  .footer-logo-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
