.general {
  width: 100%;

  & .general-element {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }

  @media screen and (max-width: 1240px) {
    & .general-element {
      grid-template-columns: 1fr 1fr;
    }
  }

  @media screen and (max-width: 500px) {
    & .general-element {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
  }
}
