.header {
  //position: relative;
  z-index: 100;
  width: 100%;
  padding: 32px calc((100% - 91.666667vw) / 2);
  display: flex;
  justify-content: space-between;
  transition: 0.3s;
  background: #27273d;

  & .logo {
    display: flex;
    flex-direction: row;
    color: transparent;
    float: left;
    width: 300px;
    text-align: right;
    & .img {
      width: 100%;
      height: auto;
    }

    & .AIimage {
      width: 33.33px;
      height: auto;
      margin-left: 4px;
      margin-top: -12px;
    }
  }
  & .links {
    & a {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      padding: 16px 27px;
      border-radius: 60px;
      border: 0.4px solid #373773;
      background: rgba(50, 50, 108, 0.6);
      font-size: 16px;
      font-weight: 600;
      line-height: 19px;
      letter-spacing: 0em;
      text-decoration: none;
      color: #dfdef4;
      cursor: pointer;

      &::after {
        display: block;
        width: 24px;
        height: 24px;
        margin-left: 10px;
        content: '';
        background: url(/images/arrow-right-purple.svg) no-repeat center /
          contain;
      }
    }
  }
  .mobile-toggle {
    display: none;
  }
  .mask {
    display: none;
  }
  @media (max-width: 650px) {
    align-items: center;

    & .logo {
      width: 177px;
    }

    & .links a {
      padding: 9px 16px;
      font-size: 11px;
      line-height: 13px;
      letter-spacing: -0.01em;

      &::after {
        display: none;
      }
    }
  }
}

.header-mask {
  width: 100%;
  height: 100px;
  @media (max-width: 767px) {
    height: 72px;
  }
}
