.block-img-text-and-btn-wrap {
  background: #27273d;
  border-radius: 40px;
  width: 100%;
  padding: 64px 0 64px 8.4vw;
  position: relative;
  overflow: hidden;
  margin: 80px 0;
}
.block-img-text-and-btn-wrap img {
  position: absolute;
  right: 80px;
  bottom: -20px;
}
@media screen and (min-width: 1200px) and (max-width: 1500px) {
  .block-img-text-and-btn-wrap img {
    right: -50px !important;
  }
}
@media screen and (min-width: 1201px) {
  .block-img-text-and-btn-wrap img {
    max-height: 125%;
    height: 125%;
    max-width: none;
    width: auto;
  }
}
.block-img-text-and-btn-content {
  max-width: 50%;
  z-index: 2;
  position: relative;
}
.section-block-img-text {
  max-width: 91.666667%;
  margin-right: auto;
  margin-left: auto;
  padding: 0;
  background: none;
  border-radius: 40px;
}
.block-img-text-and-btn-title h1,
.block-img-text-and-btn-title h2,
.block-img-text-and-btn-title h3,
.block-img-text-and-btn-title h4,
.block-img-text-and-btn-title p {
  font-family: Lato;
  font-size: 36px;
  font-weight: 900;
  line-height: 120%;
  text-align: left;
  margin-bottom: 16px;
  color: #ffffff;
}
.block-img-text-and-btn-text p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.20000000298023224px;
  text-align: left;
  color: #f8f8ff;
}
.block-img-text-and-btn-content .talk .btn-yellow {
  margin-top: 0;
}
.block-img-text-and-btn-wrap .btn-yellow {
  margin-top: 32px !important;
}
.btn-yellow {
  font-family: Lato, Roboto, sans-serif;
  font-style: normal;
  font-weight: 700 !important;
  max-width: 100%;
  padding: 14px 72px;
  background: #ffdd2c;
  font-size: 16px;
  text-align: center;
  max-height: 100%;
  border-radius: 24px;
  color: #27273d;
  display: inline-block;
  transition: background-color 0.3s ease;
  text-decoration: none;
  box-shadow: 0px 20px 40px 0px #ff9a0333;
}

.btn-yellow:hover {
  background: #ffca0f;
  color: #27273d;
  text-decoration: none;
}

.btn-yellow:active {
  background: #fcb80a;
}
@media screen and (max-width: 800px) and (min-width: 500px) {
  .block-img-text-and-btn-wrap img {
    min-width: 550px !important;
    max-width: 550px !important;
    right: 20px;
  }
  .block-img-text-and-btn-wrap {
    padding: 278px 65px 40px 24px !important;
  }
}
@media screen and (max-width: 1200px) {
  .block-img-text-and-btn-wrap img {
    position: absolute;
    min-width: 650px;
    max-width: 650px;
    top: -90px;
  }
  .block-img-text-and-btn-wrap {
    padding: 378px 8.4vw 40px;
  }
  .block-img-text-and-btn-content {
    max-width: 100%;
  }
}
@media screen and (max-width: 820px) {
  .block-img-text-and-btn-wrap {
    padding: 378px 8.4vw 40px 8.4vw;
  }
}
@media screen and (max-width: 500px) {
  .block-img-text-and-btn-title p {
    font-size: 28px;
    line-height: 33.6px;
    letter-spacing: 0.5px;
  }
  .block-img-text-and-btn-wrap {
    padding: 246px 24px 32px;
  }
  .block-img-text-and-btn-wrap img {
    position: absolute;
    top: -75px;
    right: -60px;
    min-width: 465px;
    height: 357px;
  }
  .block-img-text-and-btn-title h1,
  .block-img-text-and-btn-title h2,
  .block-img-text-and-btn-title h3,
  .block-img-text-and-btn-title h4,
  .block-img-text-and-btn-title p {
    font-size: 28px;
  }
  .btn-yellow {
    width: 100%;
  }
}
