.estimate-card {
  padding-top: 64px;
  position: sticky;
  top: 32px;

  @media screen and (max-width: 820px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 24px 32px;
    border: none;
    border-radius: unset;
    background: #fff;
    box-shadow: 0px 10px 40px 0px #0000800d;

    & .estimate-title {
      display: none;
    }

    & .estimate-scroll {
      margin: 0;
      font-size: 11px;
      line-height: 16.5px;
      padding: 12px 22px;
    }
  }

  @media screen and (max-width: 650px) {
    margin: 0;
    padding: 16px;

    & .estimate-scroll {
      padding: 12px 19px;
    }
  }

  @media screen and (max-width: 370px) {
    & .estimate-scroll {
      padding: 10px 14px;
    }
  }
}

.estimate-title {
  text-align: center;
}

.estimate-scroll {
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
  margin: 32px auto 0 auto;
  padding: 12px 48px;
  border-radius: 24px;
  box-shadow: 0px 15px 30px 0px #8585ff33;
  background: #8585ff;
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5em;
  letter-spacing: 0.5px;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    background: #6161db;
  }

  &:active {
    background: #4343b7;
  }
}

.estimate {
  position: relative;
  margin-top: 16px;
  padding: 32px;
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 10px 40px 0px #0000800d;

  & .details {
    display: flex;
    flex-wrap: wrap;
    gap: 16px 40px;

    & .block {
      max-width: calc((100% - 40px) / 2);
    }

    & .item {
      padding: 2px 7px;
      border-radius: 100px;
      font-size: 11px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.2777777910232544px;
      color: #8585ff;
      background: #f3f3ff;

      &.first {
        max-width: calc(100% - 16px);
      }
    }
  }

  & .block {
    flex: 1 1 40%;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;

    & > span {
      word-wrap: none;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .title {
      display: block;
      width: 100%;
      margin-bottom: 4px;
      font-size: 11px;
      font-weight: 300;
      line-height: 16px;
      letter-spacing: 0.2777777910232544px;
    }

    & .item-dots {
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0.2777777910232544px;
      color: #8585ff;
    }

    & .hover {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
      opacity: 0;
      z-index: 0;
      cursor: pointer;
      transition: all 0.3s;
      z-index: -1;
      position: absolute;
      top: -8px;
      right: -16px;
      left: -16px;
      background: #fefefe;
      box-shadow: 0px 10px 40px 0px #0000800d;
      border-radius: 12px;
      padding: 8px 16px;
      transition: all 3;

      & .title {
        width: 100%;
      }
    }

    &:hover {
      & .hover {
        opacity: 1;
        z-index: 10;
      }
    }
  }

  & .separator {
    margin: 16px 0;
    border-top: 1px solid #dfdef4;
  }

  & .summary {
    & .hours {
      display: flex;
      gap: 40px;

      & .block {
        flex: 1 1 40%;
        max-width: calc((100% - 40px) / 2);

        & .title {
          margin-bottom: 4px;
        }
        & .item {
          font-size: 11px;
          font-weight: 900;
          line-height: 16px;
          letter-spacing: 0.2777777910232544px;
        }
        & .delimiter {
          display: none;
        }
      }
    }
  }

  & .price {
    margin-top: 16px;
    display: flex;
    flex-wrap: wrap;
    gap: 16px 40px;

    & .block {
      flex: 1 1 40%;
      max-width: calc((100% - 40px) / 2);

      & .title {
        font-size: 14px;
        font-weight: 900;
        line-height: 16px;
        letter-spacing: 0.2777777910232544px;
      }

      & .item {
        font-size: 24px;
        font-weight: 900;
        line-height: 29px;
        letter-spacing: 0.5px;
      }
    }
  }

  @media screen and (max-width: 1240px) {
    & .details {
      gap: 16px 25px;

      & .block {
        max-width: calc((100% - 25px) / 2);
      }
    }

    & .price {
      gap: 16px 25px;

      & .block {
        max-width: calc((100% - 25px) / 2);
      }
    }

    & .summary {
      & .hours {
        gap: 25px;

        & .block {
          max-width: calc((100% - 25px) / 2);
        }
      }
    }
  }

  @media screen and (max-width: 820px) {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: unset;
    box-shadow: unset;
    background: unset;

    & .details,
    & .separator {
      display: none;
    }

    & .summary {
      display: flex;
      justify-content: space-between;
      gap: 7vw;

      & .hours {
        margin: 0;
        max-width: 100%;
        gap: 7vw;

        & .block {
          flex: 0 0 60px;
          max-width: 60px;

          & .title {
            white-space: unset;
          }
          & .item {
            font-size: 14px;
          }
        }
      }

      & .price {
        margin: 0;
        max-width: 100%;
        flex-wrap: nowrap;
        gap: 7vw;

        & .block {
          flex: 0 0 107px;
          max-width: 107px;

          & .title {
            white-space: unset;
            font-size: 11px;
          }

          &:last-child {
            max-width: fit-content;
          }
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    & .summary {
      display: flex;
      justify-content: space-between;
      gap: 3vw;

      & .hours {
        gap: 3vw;
      }

      & .price {
        gap: 3vw;
      }
    }
  }

  @media screen and (max-width: 650px) {
    & .summary {
      & .hours {
        display: none;
      }

      & .price {
        & .block {
          flex: 1 1 auto;
          max-width: 95px;

          & .hover {
            display: none;
          }

          & .title {
            margin-bottom: 0;
            line-height: 14px;
          }

          & .item {
            font-size: 20px;
            line-height: 24px;
          }

          &:last-child {
            margin-right: auto;
            max-width: 120px;
          }
        }
      }
    }
  }
}
