.flex {
  display: flex;
  align-items: center;
}

.AIQuestionsCard-card {
  position: relative;
  flex: 1 1 300px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 24px;
  background-color: #ffffff;
  box-shadow: 0px 10px 40px 0px #0000800d;
  border-radius: 20px;

  &:first-child {
    flex: 1 0 100%;
  }

  &::before {
    position: absolute;
    inset: 0;
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 0px 10px 40px 0px #0000800d;
    transition: 0.5s;
    z-index: -1;
    content: '';
    display: block;
  }

  &:hover::before {
    inset: -7px -10px;
    box-shadow: 0px 20px 40px 0px #0000800d;
  }

  & .title {
    margin: auto 0 8px;
    font-size: 18px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.5px;
    margin-bottom: 8px;

    & .sub_title {
      font-size: 16px;
      font-weight: 300;
      line-height: 36px;
      letter-spacing: 0.3px;
    }
  }

  & .icon {
    height: 59px;
    width: auto;
  }

  & .note {
    //font-weight: 500;
    margin-bottom: 32px;
    font-size: 14px;
    color: #27273d;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.2800000012px;
  }

  &.selected {
    &::before {
      background: #27273d;
    }

    & .title {
      color: #f8f8ff;
    }

    & .note {
      color: #f8f8ff;
    }
  }

  @media screen and (max-width: 820px) {
    & .icon {
      height: 56px;
    }
  }

  .industry_summary {
    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #7e7e9b;
  }

  .AIQuestionsCard-input {
    border-bottom: 1px solid #dfdef4;

    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    text-align: left;
    color: #7e7e9b;

    .MuiInput-input {
      //color: #25252f;
      color: #7e7e9b;
      font-weight: 300;
    }

    &:hover {
      border-bottom: 1px solid #bfbfff;
    }
  }

  .MuiInputBase-root {
    border-bottom: 1px solid #dfdef4;
    font: inherit;
    color: inherit;

    .MuiSelect-root {
      //color: #25252f;
      color: #7e7e9b;
      font-weight: 300;
    }

    .MuiSelect-select:focus {
      background-color: unset;
    }

    &:after,
    &:before {
      display: none;
    }

    &:hover {
      border-bottom: 1px solid #bfbfff;
    }
  }

  .MuiSelect-icon {
    color: #dfdef4;
  }
}

.MuiMenu-paper {
  max-height: 200px;
  border: none;
  border-top: 1px solid #8585ff;
  background: #ffffff;
  box-shadow: 0px 6px 7px rgba(133, 133, 255, 0.1);
  border-radius: 0px 0px 20px 20px;

  .MuiMenuItem-root {
    cursor: pointer;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 32px;
    display: flex;
    align-items: center;
    color: #7e7e9b !important;
    padding: 0 24px !important;
    margin: 0;

    &:hover {
      background: #8585ff;
      color: #fff !important;
    }

    &.Mui-selected {
      background: #8585ff;
      color: #fff !important;

      &:hover {
        background: #8585ff;
        color: #fff !important;
      }
    }
  }
}

.render-value {
  white-space: normal;
}
