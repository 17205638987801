.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  margin-top: -5vw;
  padding: 0 12vw 100px;
  background-color: #27273d;
  color: #f8f8ff;

  & .block {
    max-width: 604px;
  }

  & .tag-wrapper {
    margin-top: 60px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    border-radius: 50px;
    background: rgba(122, 122, 145, 0.14);
    backdrop-filter: blur(2.47577px);

    & p {
      color: #d8d8ff;
    }
  }

  & h1,
  & p {
    color: inherit;
  }

  & h1 {
    margin: 32px 0 16px;
  }

  & .image {
    pointer-events: none;
    flex-shrink: 0;
    max-width: 706px;
    width: 36.2vw;
  }

  & .badge-image {
    margin: 32px 0;
  }

  @media screen and (min-width: 1900px) {
    padding: 0 230px 11vw;

    & .image {
      transform: scale(1.15) translate(2vw, 2vw);
    }

    & .block {
      max-width: 710px;
    }

    & h1 + p {
      font-size: 20px;
      line-height: 36px;
      letter-spacing: 0.277778px;
    }
  }
  @media screen and (min-width: 1500px) {
    .font--h2-big {
      min-width: 710px;
    }
  }
  @media screen and (max-width: 1120px) {
    & .image {
      margin-right: -10vw;
    }
  }

  @media screen and (max-width: 820px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    margin-top: unset;
    padding: 0 32px 11vw;

    & .block {
      max-width: 100%;
      margin-top: -20vw;
      margin-bottom: 32px;
    }

    & .image {
      width: 100%;
      max-width: 627px;
      margin: 0 auto;
      transform: translateY(-7vw);
    }
  }

  @media screen and (max-width: 650px) {
    padding: 0 16px 35vw;
    overflow: hidden;

    & .image {
      transform: unset;
    }
  }
}
