.block-title {
  margin-top: 64px;
  padding: 0 7.8vw;
  display: flex;
  flex-direction: row;
  align-items: center;

  & .sub_title {
    font-size: 16px;
    font-weight: 300;
    line-height: 36px;
    letter-spacing: 0.3px;
  }

  .font--h0 {
    background-color: #27273d;
    border-radius: 56px;
    min-width: 56px;
    width: 56px;
    height: 56px;
    color: #ffffff;
    font-weight: 900;
    font-size: 24px;
    align-items: center;
    text-align: center;
    padding-top: 15px;
  }

  .font--h3 {
    font-weight: 900;
    font-size: 24px;
    margin-left: 16px;

    @media screen and (max-width: 1500px) {
      font-size: 20px;
    }
  }

  h6 {
    margin-top: 12px;
    margin-bottom: 12px;
    max-width: 1160px;
  }

  @media screen and (max-width: 820px) {
    padding: 0;
  }

  .AIstyle {
    color: #f8f8ff;
    font-weight: 900;
    font-size: 28px;
    background-color: #8585ff;
    padding: 10px, 30px, 12px, 30px;
    border-radius: 40px;
    width: 222px;
    height: 56px;
    text-align: center;
    padding: 12px;
    margin: 0px;
    margin-left: 16px;
  }
}
