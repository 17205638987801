.section-faq {
  margin: 80px auto 80px;
  background: unset;
  display: grid;
  grid-template-columns: 1fr 1.65fr;
  padding: 0 0 0 8.4vw;
  max-width: 91.666667%;
  background: none;
  border-radius: 40px;
}
.section-faq .sticky h2 {
  margin-right: 20px;
  margin-bottom: 20px;
  font-size: 36px;
}
.section-faq .accordion {
  display: flex;
  flex-direction: column;
  padding: 16px 32px;
  background: #f8f8ff;
  border-radius: 40px;
  height: fit-content;
}
.section-faq .card {
  margin-bottom: 0;
  border-radius: 0 !important;
  box-shadow: none !important;
}
.section-faq .accordion > .card > .card-header {
  display: flex;
  justify-content: space-between;
  background: #f8f8ff;
  padding: 24px 0;
  -webkit-appearance: button-bevel;
}
.section-faq .card-body {
  background: #f8f8ff;
  border-radius: 0 !important;
  padding-right: 30px;
  overflow: hidden;
  transition: height 0.35s ease;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.277778px;
}
.card:hover .card-header-more{
  background-size: 120%;
  background-position-y: -3px;
}
.card-header-more {
  display: inline-block;
  margin-right: 6px;
  background-image: url(https://ik.imagekit.io/k0jemnbco/edtech/offer-plus_t1iuCBNllx.svg);
  height: 24px;
  width: 24px;
  margin-left: 15px;
  transform: rotateZ(45deg);
  transition: all .5s ease;
}
.collapsed > .card-header-more {
  background-image: url(https://ik.imagekit.io/k0jemnbco/edtech/offer-plus_t1iuCBNllx.svg) !important;
  transform: rotateZ(0);
}
.collapsed>.card-header-more{
  transform:rotateZ(0)
}
.section-faq .accordion > .card:not(:last-of-type) {
  border-bottom: 1px solid #dfdef4;
  background: #f8f8ff;
}
@media screen and (max-width: 1440px) {
  .section-faq {
    grid-template-columns: 1fr 1.4fr;
  }
}
@media screen and (max-width: 900px) {
  .section-faq {
    grid-template-columns: 1fr;
    padding-left: 0;
  }
  .sticky {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    top: unset;
    margin: 0;
  }
}
@media screen and (max-width: 820px) {
  .section-faq .sticky img {
    width: 30%;
    height: 30%;
    margin-right: 5%;
  }
}
@media screen and (max-width: 768px) {
  .section-faq .accordion > .card > .card-header {
    padding: 16px 0;
    font-size: 20px;
  }
  .section-faq .accordion {
    padding: 24px;
  }
}
@media screen and (max-width: 650px){
  .card-header-more {
    flex: none;
  }
}
@media screen and (max-width: 550px) {
  .section-faq h2 {
    max-width: 200px;
    font-size: 28px !important;
  }
  .section-faq .sticky img {
    width: 40%;
    margin-bottom: 30px;
  }
}