.platform-card {
  position: relative;
  flex: 1 1 300px;
  height: auto;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  cursor: pointer;
  background: none;
  border: none;

  &::before {
    position: absolute;
    inset: 0;
    border-radius: 20px;
    background: #ffffff;
    box-shadow: 0px 10px 40px 0px #0000800d;
    transition: 0.5s;
    z-index: -1;
    content: '';
    display: block;
  }

  &:hover::before {
    inset: -7px -10px;
    box-shadow: 0px 20px 40px 0px #0000800d;
  }

  & .title {
    margin: auto 0;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    letter-spacing: 0.5px;
    padding-left: 8px;
    color: black;
  }

  & .icon {
    height: 40px;
    width: auto;
  }

  & .note {
    padding-top: 8px;
    font-family: Lato;
    font-size: 14px;
    font-weight: 300;
    line-height: 21px;
    letter-spacing: 0.2800000011920929px;
    text-align: left;
    color: black;
  }

  &.selected {
    &::before {
      background: #27273d;
    }

    & .title {
      color: #f8f8ff;
    }

    & .note {
      color: #f8f8ff;
    }
  }

  @media screen and (max-width: 820px) {
    & .icon {
      height: 56px;
    }
  }
}
