.contact-form-container {
  position: fixed;
  inset: 0;
  padding: 20px;
  justify-content: center;
  align-items: center;
  z-index: 150;
  background: rgba(0, 0, 0, 0.4);
  display: none;

  &.visible {
    display: flex;
  }
}

.contact-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 550px;
  min-height: 595px;
  width: 100%;
  padding: 64px 48px;
  background: #ffffff;
  box-shadow: 0px 20px 40px 0px #39393959;
  transition: all 0.1s;
  border-radius: 40px;

  & h2 .font--16 {
    margin-top: 16px;
    margin-bottom: 56px;
  }

  &.process {
    flex-direction: column;
  }
  .contacts_form_fields .input {
    display: flex;
    flex-direction: column;
  }
  .contacts_form_fields .input input {
    border-radius: 0;
    height: 29px;
    width: 100%;
    border: transparent;
    border-bottom: 1px solid #dfdef4;
    outline: none;
    margin-bottom: 0;
    font-family: Lato;
    font-size: 16px;
    font-weight: 300;
    line-height: 32px;
    letter-spacing: 0em;
    color: #27273d;

    &:last-of-type {
      margin-bottom: 48px;
    }

    &.red {
      border-bottom: 1px solid red;
    }
  }

  .contacts_form_fields .btn-container {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    transition: all 0.1s;
  }
  .contacts_form_fields .btn-container .custom-file-upload {
    background-color: #9595ef;
    border: solid 1px #9595ef;
    padding: 7px 13px 6px 14px;
    height: 40px;
    width: 40px;
    cursor: pointer;
  }
  .contacts_form_fields .btn-container .custom-file-upload:hover {
    box-shadow: 0 5px 9px 0 rgba(149, 149, 239, 0.4);
  }
  .contacts_form_fields .btn-container input[type='file'] {
    display: none !important;
  }
  .contacts_form_fields .btn-container .file-name {
    font-family: Lato;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: #000000;
    margin-left: 10px;
    margin-right: 10px;
    max-width: 140px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .contacts_form_fields .btn-container #btn-file-reset {
    background: transparent;
    outline: none;
    font-family: Lato;
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.71;
    letter-spacing: 0.2px;
    color: #000000;
  }

  .contacts_form_fields .btn-container .btn-send {
    padding: 12px 70px;
    border-radius: 24px;
    box-shadow: 0px 15px 30px 0px #8585ff33;
    background: #8585ff;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    transition: 0.1s;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
  }

  .contacts_form_fields .btn-container .btn-send:hover {
    background: #6161db;
  }

  .contacts_form_fields .btn-container .btn-send:active {
    background: #4343b7;
  }

  label.hidden {
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.2777777910232544px;
  }

  .success {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    width: 275px !important;
    height: 33px;
    background-color: #ffffff !important;
    box-shadow: 0 19px 31px 0 rgba(109, 102, 134, 0.13);
    font-family: Lato;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .required__msg {
    margin: 3px 0 45px;
    min-height: 12px;
    color: red;
    font-size: 10px;

    &:first-of-type {
      margin-bottom: 36px;
    }
  }

  &.progress {
    & .action-progress-dots {
      margin: 0 auto 50px;
      display: flex;
      justify-content: center;
      gap: 10px;
    }

    & .dot {
      width: 16px;
      height: 16px;
      border-radius: 100px;
      background-color: #9595ef;
      animation: animation-dot 0.8s infinite linear;
      animation-delay: 0s;

      &:nth-child(2) {
        animation-delay: 0.15s;
      }

      &:last-child {
        animation-delay: 0.3s;
      }
    }

    & .action-progress {
      max-width: 320px;
      margin: 0 auto;
      font-family: Lato;
      font-size: 36px;
      font-weight: 900;
      line-height: 43px;
      letter-spacing: 0px;
      text-align: center;
    }
  }
  & .action-success {
    max-width: 320px;
    margin: 0 auto 40px;
    font-family: Lato;
    font-size: 36px;
    font-weight: 900;
    line-height: 43px;
    letter-spacing: 0px;
    text-align: center;
  }
  & .action-error {
    color: red;
    max-width: 320px;
    margin: 0 auto 50px;
    font-family: Lato;
    font-size: 36px;
    font-weight: 900;
    line-height: 43px;
    letter-spacing: 0px;
    text-align: center;
  }

  & .action {
    padding: 12px 48px;
    border-radius: 24px;
    box-shadow: 0px 15px 30px 0px #8585ff33;
    background: #8585ff;
    color: #ffffff;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.5em;
    letter-spacing: 0.5px;
    transition: 0.3s;
    cursor: pointer;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    width: fit-content;
  }

  & .action:hover {
    background: #6161db;
  }

  & .action:active {
    background: #4343b7;
  }

  @media screen and (max-width: 650px) {
    padding: 32px 24px;

    & h2 .font--16 {
      margin-bottom: 40px;
    }

    .contacts_form_fields .btn-container .btn-send {
      width: 100%;
      text-align: center;
    }
  }
}

@keyframes animation-dot {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(100%);
  }
  60% {
    transform: translateY(0);
  }
}
