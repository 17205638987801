@font-face {
  font-family: 'Bebas Neue';
  font-weight: 100;
  font-style: normal;
  src: url('/fonts/BebasNeue-Thin.otf') format('opentype'),
    url('/fonts/BebasNeue-Thin.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 200;
  font-style: normal;
  src: url('/fonts/BebasNeue-Light.otf') format('opentype'),
    url('/fonts/BebasNeue-Light.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 300;
  font-style: normal;
  src: url('/fonts/BebasNeue-Book.otf') format('opentype'),
    url('/fonts/BebasNeue-Book.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 400;
  font-style: normal;
  src: url('/fonts/BebasNeue-Regular.otf') format('opentype'),
    url('/fonts/BebasNeue-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bebas Neue';
  font-weight: 700;
  font-style: normal;
  src: url('/fonts/BebasNeue-Bold.otf') format('opentype'),
    url('/fonts/BebasNeue-Bold.ttf') format('truetype');
}

@font-face {
  font-family: Lato;
  src: local('Lato-Light'),
    url('/fonts/hinted-subset-Lato-Light.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-Light.svg#Lato-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato-LightItalic'),
    url('/fonts/hinted-subset-Lato-LightItalic.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-LightItalic.svg#Lato-LightItalic')
      format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato Black'), local('Lato-Black'),
    url('/fonts/hinted-subset-Lato-Black.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-Black.svg#Lato-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato Bold Italic'), local('Lato-BoldItalic'),
    url('/fonts/hinted-subset-Lato-BoldItalic.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-BoldItalic.svg#Lato-BoldItalic')
      format('svg');
  font-weight: 700;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato #000 Italic'), local('Lato-BlackItalic'),
    url('/fonts/hinted-subset-Lato-BlackItalic.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-BlackItalic.svg#Lato-BlackItalic')
      format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato Italic'), local('Lato-Italic'),
    url('/fonts/hinted-subset-Lato-Italic.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-Italic.svg#Lato-Italic') format('svg');
  font-weight: 400;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato Bold'), local('Lato-Bold'),
    url('/fonts/hinted-subset-Lato-Bold.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-Bold.svg#Lato-Bold') format('svg');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Lato;
  src: local('Lato Regular'), local('Lato-Regular'),
    url('/fonts/hinted-subset-Lato-Regular.ttf') format('truetype'),
    url('/fonts/hinted-subset-Lato-Regular.svg#Lato-Regular') format('svg');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
span,
div {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

img {
  height: auto;
}

body {
  max-width: 100%;
  overflow-x: hidden;
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #27273d;
}

ul {
  list-style-type: none;
}

.img {
  display: inline-block;

  &.unvisible {
    display: none;
  }
  & source,
  & img {
    max-width: 100%;
    max-height: 100%;
  }
}

.font--h0 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 70px;
  line-height: 99.5%;
  text-transform: capitalize;
}

.font--h1 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 56px;
  line-height: 100%;
  letter-spacing: 1px;
  text-transform: capitalize;
  color: #27273d;
}
.font--h2-big {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 48px;
  line-height: 100%;
  letter-spacing: 1px;
  color: #27273d;

  @media screen and (max-width: 1500px) {
    font-size: 36px;
  }
}
@media (max-width: 1024px) {
  .font--h1 {
    font-size: 3.88889vw;
  }
  .hero-top .font--h1 {
    font-size: 48px;
  }
}

.font--h2 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 36px;
  line-height: 120%;
}

.font--h3 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 28px;
  line-height: 120%;
  letter-spacing: 0.5px;
  text-transform: none;
}

.font--h4 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 120%;
  letter-spacing: 0.5px;
}

@media (max-width: 650px) {
  .font--h0,
  .font--h1,
  .font--h2 {
    font-size: 48px;
    line-height: 100%;
  }
  .font--h3,
  .font--h4 {
    font-size: 24px;
  }
  .hero-top .font--h1 {
    font-size: 48px;
    letter-spacing: 0;
  }
}

.font--20 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.277778px;
}

.font--18 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 18px;
  line-height: 32px;
  letter-spacing: 0.277778px;
}

.font--16 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.277778px;
  color: #27273d;
}
.font--16-gray {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: 0.2px;
  color: #7e7e9b;
}
.font--14 {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.277778px;
  color: #27273d;
}
.font--14-gray {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: #7e7e9b;
}
.font--caption {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.277778px;
}
.purple-link {
  color: #6060f9;
}
a:hover {
  text-decoration: none !important;
}
.purple-link:hover {
  color: #6060f9;
  text-decoration: underline !important;
}
.purple-link:active {
  color: #a35ffa;
}
.purple-link.dark-mode {
  color: #9696f3;
}
.purple-link.dark-mode:hover {
  color: #9696f3;
}
.purple-link.dark-mode:active {
  color: #a35ffa;
}
.font--12 {
  font-size: 12px;
  line-height: 14px;
  font-weight: 600;
}

.frame-footer {
  border: 0px;
  width: 100%;
  height: 1400px;
  overflow: hidden;

  margin-top: 100px;

  .location-item {
    text-decoration: none;
  }
  a {
    text-decoration: none;
  }
}

a {
  text-decoration: none;
}

.location-item {
  text-decoration: none;
}
