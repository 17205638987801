.option-card {
  position: relative;
  flex: 1 1 373px;
  min-height: 159px;
  display: flex;
  flex-direction: column;
  padding: 24px;
  z-index: 1;

  cursor: pointer;

  &::before {
    position: absolute;
    inset: 0;
    display: block;
    content: '';
    transition: 0.5s;
    border-radius: 20px;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 10px 40px 0px #0000800d;
    z-index: -1;
  }

  &:hover::before {
    inset: -7px -10px;
    padding: 31px 34px;
    box-shadow: 0px 20px 40px 0px #0000800d;
  }

  & .icon-title {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 8px;
    padding-right: 30px;
  }

  & .title {
    font-size: 18px;
    line-height: 23.4px;
    font-weight: 900;
  }

  & .icon {
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: auto;
    width: 40px;
    height: 40px;
    border-radius: 100px;
    background: #f3f3ff;
    transition: 0.5s;

    & .img:not(.unvisible) {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & img {
      width: 24px;
      height: 24px;
    }
  }

  &.selected {
    &::before {
      background: #f3f3ff;
    }

    & .icon {
      background: #8585ff1a;
    }
  }

  @media screen and (max-width: 1024px) {
    min-height: unset;
  }

  @media screen and (max-width: 500px) {
    flex: 1 1 auto;
  }
}
