.multi-select-question {
  font-weight: 300;
  margin-top: 42px;
  width: calc(100% - 64px);

  .MuiInput-input {
    color: #7e7e9b;
    font-weight: 300;
  }

  &:hover {
    border-bottom: 1px solid #bfbfff;
  }
}

.render-value {
  white-space: normal;
}
