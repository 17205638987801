.select {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 24px;
  top: 24px;
  background: transparent;
  border-radius: 50%;
  transition: 0.5s ease;
}
