.platforms-container {
  width: 91.666667vw;
  margin: 64px auto 0;

  @media screen and (max-width: 650px) {
    margin-left: 16px;
    margin-right: 16px;
    width: auto;
  }

  .platforms {
    padding: 32px 7.8vw;
  }
}

.platforms {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: space-between;
  //gap: 40px;
  gap: 25px;

  &.platforms-wrap {
    flex-wrap: wrap;
  }

  & > .img {
    position: absolute;
    top: 100px;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    & > source,
    & > img {
      width: 100%;
    }
  }

  @media screen and (max-width: 1240px) {
    gap: 20px;
  }

  @media screen and (max-width: 820px) {
    width: auto;
    flex-wrap: wrap;

    & .platform-card-container {
      flex: 1 1 40%;
    }
  }

  @media screen and (max-width: 500px) {
    gap: 16px;

    & .platform-card-container {
      flex: 1 1 100%;
    }
  }
}
